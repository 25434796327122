import styled from 'styled-components';
export const AppWrapper = styled.div`
  padding: 15px 25px;
`;

export const AddSessionLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.2em;

  > a {
    font-family: 'Ubuntu', sans-serif;
    text-decoration: none;
  }
`;
