import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} from 'react-google-maps';

import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

import SmokeSessionMarker from './marker';

const BlazinMap = withScriptjs(
  withGoogleMap(props => {
    const markers = props.smokeSessions.map(
      (smokeSession, index) =>
        smokeSession.lat &&
        smokeSession.lng && (
          <SmokeSessionMarker
            strain={smokeSession.strain}
            key={index + smokeSession.lat + smokeSession.lng}
            doctor={smokeSession}
            location={{
              lat: smokeSession.lat,
              lng: smokeSession.lng,
            }}
          />
        ),
    );

    return (
      <GoogleMap defaultZoom={10} center={props.mapCenter}>
        <MarkerClusterer
          onClick={() => console.log('marker cluster click')}
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {markers}
        </MarkerClusterer>
      </GoogleMap>
    );
  }),
);

export default BlazinMap;
