import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import * as routes from '../../constants/routes';
import AuthUserContext from '../Session/AuthUserContext';
import SignOutButton from '../SignOut';

const Header = styled.header`
  display: flex;
  background: #00a566;
  color: #f0f0f0;
  margin-bottom: 1.45rem;

  a {
    color: #f0f0f0;
    text-decoration: none;
    font-family: 'Ubuntu', sans-serif;
  }
`;

const HeaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
`;

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? <NavigationAuth /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = () => (
  <Header>
    <HeaderContainer>
      <Link to={routes.LANDING}>WhatUBlazin</Link>

      <Link to={routes.HOME}>My Sessions</Link>

      <Link to={routes.ACCOUNT}>Account Settings</Link>

      <SignOutButton />
    </HeaderContainer>
  </Header>
);

const NavigationNonAuth = () => (
  <Header>
    <HeaderContainer>
      <Link to={routes.LANDING}>WhatUBlazin</Link>
      <Link to={routes.SIGN_IN}>Sign In</Link>
    </HeaderContainer>
  </Header>
);

export default Navigation;
