import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import * as routes from '../constants/routes';

import MapContainer from '../components/Maps/index';
import Layout from '../components/layout';
import {
  AppWrapper,
  AddSessionLinkWrapper,
} from '../components/styled';

const LandingPage = () => (
  <Fragment>
    <h1>Welcome to the WhatUBlazin App</h1>
    <p>See what type of marijuana the world is smoking.</p>
    <p>No account needed to contribute</p>
    <AddSessionLinkWrapper>
      <Link to={routes.ADD_SMOKE_SESSION}>Add a smoke session</Link>
    </AddSessionLinkWrapper>
  </Fragment>
);

export default () => (
  <Layout>
    <AppWrapper>
      <LandingPage />
    </AppWrapper>
    <MapContainer />
  </Layout>
);
