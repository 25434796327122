import React from 'react';
import styled from 'styled-components';

import { withFirebase } from '../Firebase/FirebaseContext';

const StyledButton = styled.button`
  border: 0;
`;

const SignOutButton = ({ firebase }) => (
  <StyledButton type="button" onClick={firebase.doSignOut}>
    Sign Out
  </StyledButton>
);

export default withFirebase(SignOutButton);
