import React, { Component } from 'react';

// import Layout from '../layout';
// import withAuthorization from '../Session/withAuthorization';
import { withFirebase } from '../Firebase/FirebaseContext';
import AuthUserContext from '../Session/AuthUserContext';
import BlazinMap from './map';

const fromObjectToList = object =>
  object
    ? Object.keys(object).map(key => ({ ...object[key], index: key }))
    : [];

class MapContainerBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initFirebase: false,
      smokeSessions: [],
      mapCenter: { lat: 33.749, lng: -84.388 },
      viewUserOnly: false,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this.state.initFirebase) {
      this.props.firebase.onceGetSmokeSessions().then(snapshot =>
        this.setState(() => ({
          smokeSessions: fromObjectToList(snapshot.val()),
        })),
      );

      this.setState({ initFirebase: true });
    }
  };

  getAllSmokeSessions = () => {
    this.props.firebase.onceGetSmokeSessions().then(snapshot =>
      this.setState(() => ({
        smokeSessions: fromObjectToList(snapshot.val()),
        viewUserOnly: false,
      })),
    );
  };

  getUserSmokeSessions = email => {
    this.props.firebase
      .onceGetSmokeSessionsByEmail(email)
      .then(snapshot =>
        this.setState(() => ({
          smokeSessions: fromObjectToList(snapshot.val()),
          viewUserOnly: true,
        })),
      );
  };

  componentDidMount() {
    this.firebaseInit();

    if (localStorage.hasOwnProperty('whatUBlazinMapCenter')) {
      // get the key's value from localStorage
      let mapCenter = localStorage.getItem('whatUBlazinMapCenter');

      // parse the localStorage string and setState
      try {
        mapCenter = JSON.parse(mapCenter);
        this.setState({
          mapCenter: {
            lat: mapCenter.lat,
            lng: mapCenter.lng,
          },
        });
      } catch (e) {
        // handle empty string
        this.setState({ mapCenter: { lat: 33.749, lng: -84.388 } });
      }
    }
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  render() {
    const { smokeSessions } = this.state;

    return (
      <React.Fragment>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              {authUser && (
                <button
                  onClick={() => {
                    if (this.state.viewUserOnly) {
                      this.getAllSmokeSessions();
                    } else {
                      this.getUserSmokeSessions(authUser.email);
                    }
                  }}
                >
                  {this.state.viewUserOnly
                    ? 'All Sessions'
                    : 'My Sessions'}
                </button>
              )}
            </div>
          )}
        </AuthUserContext.Consumer>
        <BlazinMap
          mapCenter={this.state.mapCenter}
          smokeSessions={smokeSessions}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBJovmEFQX6p7sbWyMBCcx5xyrOeBIPedA&v=3.exp`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: `600px`, width: `100%` }} />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </React.Fragment>
    );
  }
}

// const authCondition = authUser => !!authUser;

// const MapContainer = withAuthorization(authCondition)(
//   MapContainerBase,
// );

const MapContainer = withFirebase(MapContainerBase);

export default () => (
  <div>
    <MapContainer />
  </div>
);
