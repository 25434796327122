import React from 'react';
import { Marker } from 'react-google-maps';
import StethoscopeIcon from '../../images/leaf.png';

export default class SmokeSessionMarker extends React.Component {
  render() {
    return (
      <Marker
        onClick={() =>
          alert(
            'You were blazin some ' + this.props.strain
              ? this.props.strain
              : 'dank trees',
          )
        }
        position={this.props.location}
        icon={StethoscopeIcon}
      />
    );
  }
}
